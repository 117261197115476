.error-message {
  color: #FF4842;
  /* Set the desired text color for error messages */
  font-size: 12px;
  /* Set the desired font size for error messages */
  margin-top: 3px;
  padding-left: 10px;
  /* Add any other styles as needed */
}

body{
  position: inherit;
  top: 0 !important;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.css-m5seg7 {
  top: 19px;
}

.css-3o3n0n, .css-jlp0cq {
  min-width: 5vw;
  margin-right: 2vw;
}

@media only screen and (max-width: 900px) {
  #google_translate_element {
    position: fixed;
    top: 13px !important;
    right: 15% !important;
    z-index: 100 !important;
  }

  .goog-te-gadget .goog-te-combo {
    
    padding: 6px !important;
    color: black;
    font-size: 12px !important;
    margin:8px 0px !important;
    text-align: center!important;
   
  }

}

#google_translate_element {
  position: fixed;
  top: 17px;
  right: 10px;
  z-index: 100 !important;
}

.goog-te-gadget {
  /* display: -webkit-box; */
  padding-left: 20px;
  padding-right: 0px;
  color: #fff0 !important;
}
.goog-te-gadget .goog-te-combo {
  margin: 4px 0;
  /* padding: 11px;
  color: black;
  border-radius: 9px;
  font-weight: bold;
  font-size: 12px;
  max-width: 120px; */

  padding: 10.5px 8px;
  color: #333333;
  border-radius: 9px;
  font-weight: normal;
  font-size: 17px ;
  max-width: 177px;
  min-width: 117px;
  border:none;
  text-align: center;
  outline:none;

}
/* .goog-te-gadget .goog-te-combo :focus{
outline:none;
border: none;
} */
.goog-te-gadget .goog-te-menu {
  border: none; /* Remove the border */
  outline:none;

}

/* .css-e53awj-MuiStack-root, .css-gmwslw-MuiStack-root{
  margin-right: 64px;
} */


.goog-te-combo {
  margin-left:0px  !important;
  padding: 5px;
  border-radius: 5px;
  background: transparent;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd,
.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none;
}

.btnSelecthover:hover{
  background-color: #f44336;

}

.btn-parter-logo{
  width:10px;
  height:100%;
  position:absolute;
  right:0;

}

.btnArrow-parter-logo{

  font-size:2rem;
  color:black;
  /* background-color:rgba(0, 0, 0,0.1); */
  
}

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 250px;
    overflow: hidden;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .imgVideo:hover + .iconYoutube
    {
      color: #f44336 !important;
    }


  .imgVideo {
      transition: transform 0.2s ease; /* Animation */
   }
   
  .imgVideo:hover {
      transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
      border-radius: 10px;
      
    }

    .my-radio {
      width:  18px;
      height:  18px;
      
  }
  .my-radio:hover {
   
    cursor: pointer;
}


  .error {
    color: red;
    font-size: 12px;
    padding-left: 14px;
 
  }

  @media only screen and (min-width: 600px) and (max-width:900px) {
    .partners-slider .slick-slide > div {
      margin: auto 20px; 
    }
  }  
  

@media only screen and (min-width: 900px) {
  .partners-slider .slick-slide > div {
    margin: auto 40px; 
  }
}  


@media only screen and (max-width: 600px) {
  .partners-slider .slick-slide > div {
    margin: 0px 12px; 
  }
}  


  
  